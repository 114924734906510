<template>
    <div class="home">
        <div v-if="isMobileType==='pc'">
            <el-row class="product-presentation">
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                    <el-row class="product-bg-left">
                        <el-col :xs="2" :sm="4" :md="4" :lg="6"></el-col>
                        <el-col :xs="22" :sm="20" :md="20" :lg="18">
                            <div class="grid-content">
                                <div class="company-info left-to-right">
                                    <!-- <img :src="`${productUrl}/logo.png`" class="company-log">
                                    <span class="company-name">深圳创链数据科技有限公司</span> -->
                                </div>
                                <div class="company-slogan hide-to-show">
                                    <span class="slogan-placard">【好店码】收款码可以帮您</span>
                                    <span class="slogan-subject">收款、管账、分利、躺赚</span>
                                    <span class="slogan-placard"> — 收益权投资管理工具创导者</span>
                                </div>
                                <div class="company-slogan" style="flex-direction: row">
                                    <div class="company-video" @click="watchVideo">
                                        <img class="see-video" src="../../assets/images/video-icon.png" alt="">
                                        <span>观看品牌视频</span>
                                    </div>
                                    <div class="company-video" @click="connectUs">
                                        <img class="connect-us" src="../../assets/images/pen-icon.png" alt="">
                                        <span>联系我们</span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12">
                    <el-row class="product-bg-right">
                        <el-col :xs="22" :sm="20" :md="20" :lg="18">
                            <div class="grid-content hide-to-show">
                                <img :src="`${productUrl}/lamplight-icon.png`" alt="" class="lamplight">
                            </div>
                        </el-col>
                        <el-col :xs="2" :sm="4" :md="4" :lg="6"></el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div v-else class="product-position">
            <el-row class="product-presentation">
                <el-col>
                    <el-row class="product-bg-mobile">
                        <el-col>
                            <div class="grid-content">
                                <div class="company-slogan hide-content-show  ">
                                    <span class="slogan-placard slogan-subject-mobile">【好店码】收款码可以帮您</span>
                                    <span class="slogan-subject slogan-subject-mobile ">收款、管账、分利、躺赚</span>
                                    <span class="slogan-placard slogan-subject-mobile "> — 收益权投资管理工具创导者</span>
                                </div>
                                <div class="product-content hide-content-show" style="height: auto;">
                                    <img :src="`${productUrl}/lamplight-icon-mobile.png`" alt="" class="mobile-lamplight">
                                    <span class="slogan-placard">扫一扫即刻体验「好店码」</span>
                                </div>
                                <div class="company-slogan-footer slogan-subject-mobile hide-content-show ">
                                    <div class="company-video" @click="watchVideo">
                                        <img class="see-video" src="../../assets/images/video-icon.png" alt="">
                                        <span>观看品牌视频</span>
                                    </div>
                                    <div class="company-video" style="margin:0" @click="connectUs">
                                        <img class="connect-us" src="../../assets/images/pen-icon.png" alt="">
                                        <span>联系我们</span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Home',
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            },
            watchVideoPlay: {
                type: Function,
                default() {
                    return '';
                },
            }
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
            }
        },
        mounted() {
            this.productUrl = this.$productUrl;
        },

        methods: {
            watchVideo() {
                this.$emit("watchVideoPlay", {});
            },
            connectUs() {
                this.$emit("watchConnectUs", {});
            },
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.product-presentation {
  position: relative;
  display: flex;
  flex: 1;
  background: #000;
  .product-bg-left {
    height: @screenH;
    background: url("https://staticweb.innochain.tech/web-product/product/product-left-icon.png")
      no-repeat;
  }
  .product-bg-right {
    height: @screenH;
    background: url("https://staticweb.innochain.tech/web-product/product/product-right-icon.png")
      no-repeat;
  }
  .grid-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #fff;
    height: 100vh;
  }
  .product-bg-mobile {
    height: @screenH;
    background: url("https://staticweb.innochain.tech/web-product/product/yidongduanditu.png")
      no-repeat;
    background-size: 100%;
    .product-content {
      .grid-content;
      justify-content: center;
      align-items: center;
    }
    .company-slogan {
      margin-top: 10vh;
    }
    .mobile-lamplight {
      width: 11.3rem;
    }
  }

  .company-info {
    position: absolute;
    display: flex;
    margin-top: 2.5vh;
    .company-log {
      height: 1.5rem;
    }
    .company-name {
      font-size: 1.2rem;
      margin-left: 0.8rem;
    }
  }
  .company-slogan {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 10rem;
    .slogan-subject {
      display: flex;
      font-size: 2.2rem;
    }
    .company-video {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      background: @butomBg;
      padding: 0 1rem;
      margin-right: 1rem;
      border-radius: 0.2rem;
      cursor: pointer;
      .see-video {
        width: 1.4rem;
        margin-right: 0.3rem;
      }
      .connect-us {
        width: 1.1rem;
        margin-right: 0.3rem;
      }
    }
  }
  .slogan-placard {
    display: flex;
    font-size: 1.1rem;
    margin-top: 0.8rem;
  }
  .lamplight {
    width: 25rem;
  }
  .company-slogan-footer {
    .company-slogan;
    flex-direction: row;
    margin-top: 5vh;
  }
}

.slogan-subject-mobile {
  justify-content: center;
}
</style>
