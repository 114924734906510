<template>
    <div class="product-position">
        <div v-if="isMobileType==='pc'" style="display: flex; flex: 1;  height: 67vh;align-items: center;">
            <el-container>
                <el-header class="proprty-header hide-content-show" style="padding:0">请留下您的联系方式，我们会尽快与您取得联系</el-header>
                <el-main class="proprty-main-box">
                    <div class="contact-way-box hide-content-show">
                        <img :src="`${productUrl}/lianxidi.png`" alt="" class="lianxidi">
                        <div class="contact-way-info">
                            <ul class="contact-way-info-list">
                                <li> <span>姓名</span> <input type="text" placeholder="请输入您的名称" v-model="customerContact.name"></li>
                                <li> <span>电话</span> <input type="text" placeholder="请输入您的联系电话" v-model="customerContact.tel"></li>
                                <li> <span>公司名称</span> <input type="text" placeholder="请输入您的公司名称" v-model="customerContact.company"></li>
                                <li>
                                    <span>咨询的业务</span>
                                    <el-select v-model="customerContact.content" placeholder="请选择标签">
                                        <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label">
                                        </el-option>
                                    </el-select>
                                </li>
                            </ul>
                            <div class="contact-way-info-list-subitm" @click="customerContactAdd">提交信息</div>
                        </div>
                    </div>
                </el-main>
                <el-main class="proprty-main-box connect-box">
                    <div style="display: flex;flex: 1;"> </div>
                    <div class="company-way">
                        <div class="company-name">深圳创链数据科技有限公司</div>
                        <ul class="company-list">
                            <li><img src="../../assets/images/phone-icon.png" alt="" class="connect-icon"> {{companyPhone}}</li>
                            <li><img src="../../assets/images/qq-icon.png" alt="" class="connect-icon"> {{companyQQ}}</li>
                            <li><img src="../../assets/images/mail-icon.png" alt="" class="connect-icon"> {{mailInfo}}</li>
                            <li style="font-size: 12px;"><img src="../../assets/images/address-icon.png" alt="" class="connect-icon">{{companySite}}</li>
                        </ul>
                    </div>
                    <div class="company-way">
                        <img :src="`${productUrl}/QR-code.png`" alt="" style="width: 16rem;">
                    </div>
                    <div class="company-way">
                        <div class="company-name">友情链接：</div>
                        <ul class="company-list">
                            <li> <a href="https://antchain.antgroup.com/">蚂蚁区块链</a> <a href="http://www.dairphone.cn/"> 阿尔丰</a></li>
                            <li> <a href=" https://trustsql.qq.com/">腾讯区块链</a> <a href="https://36kr.com/"> 36氪</a></li>
                            <li> <a href="https://ethereum.org/zh/">以太坊</a> <a href="http://www.f5-futurestore.com/"> F5未来商店 </a></li>
                            <li> <a href="https://www.chinapnr.com/">汇付天下</a></li>
                        </ul>
                    </div>
                </el-main>
                <div class="company-way-info">
                    <span>{{companyName}}
                        <a href="https://beian.miit.gov.cn/">{{recordsInfo}}</a>
                    </span>
                    <span>{{recordsInfoName}}</span>
                </div>
            </el-container>
        </div>
        <div v-else>
            <div class="greenroom-presentation hide-to-show" style="flex-direction: column">
                <div class="proprty-header-mobile hide-content-show">
                    <div class="proprty-header-text">请留下您的联系方式</div>
                    <div class="proprty-header-text">我们会尽快与您取得联系</div>
                </div>
                <div class="contact-way-box">
                    <div class="contact-way-info hide-content-show" style="border:0">
                        <ul class="contact-way-info-list ">
                            <li> <span>姓名</span> <input type="text" placeholder="请输入您的名称" v-model="customerContact.name"></li>
                            <li> <span>电话</span> <input type="text" placeholder="请输入您的联系电话" v-model="customerContact.tel"></li>
                            <li> <span>公司名称</span> <input type="text" placeholder="请输入您的公司名称" v-model="customerContact.company"></li>
                            <li>
                                <span>咨询的业务</span>
                                <el-select v-model="customerContact.content" placeholder="请选择标签">
                                    <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label">
                                    </el-option>
                                </el-select>
                            </li>
                        </ul>
                        <div class="contact-way-info-list-subitm" @click="customerContactAdd">提交信息</div>
                    </div>
                </div>
                <div class="connect-us">
                    <img :src="`${productUrl}/connect-us-icon.png`" alt="" class="hide-content-show">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Ajax from 'ajax-promise-es6'
    import { COMPANY_PHONE, COMPANY_QQ, MAIL_INFO, COMPANY_SITE, COMPANY_NAME, RECORDS_INFO, RECORDS_INFO_NAME } from '@/assets/connJs/menu-func'
    export default {
        name: "ConnectUs",
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            },
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
                options: [
                    { id: 1, label: '优质客户' },
                    { id: 2, label: '合作伙伴' },
                    { id: 3, label: '精英人才' },
                    { id: 4, label: '重要渠道' }
                ],
                companyPhone: COMPANY_PHONE,
                companyQQ: COMPANY_QQ,
                mailInfo: MAIL_INFO,
                companySite: COMPANY_SITE,
                companyName: COMPANY_NAME,
                recordsInfo: RECORDS_INFO,
                recordsInfoName: RECORDS_INFO_NAME,
                customerContact: {}
            }
        },
        mounted() {
            this.productUrl = this.$productUrl;
        },
        methods: {
            customerContactAdd() {
                let { customerContact, } = this;
                if (!customerContact.name) { this.$emit("isToastFun", "姓名不能为空"); return }
                if (!customerContact.tel) { this.$emit("isToastFun", "联系方式不能为空"); return }
                if (!customerContact.company) { this.$emit("isToastFun", "公司不能为空"); return }
                if (!customerContact.content) { this.$emit("isToastFun", "文章标签不能为空"); return }
                Ajax.post("http://pisabeta.innochain.tech/abt/customerContact/add", customerContact).then((res) => {
                    let resResult = typeof res === 'string' ? JSON.parse(res) : res
                    this.$emit("isToastFun", resResult.msg)
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.proprty-header-mobile-text {
  color: #fff;
  font-size: 2rem;
  line-height: 33px;
  letter-spacing: 3px;
}
.proprty-header {
  font-weight: 600;
  color: @captionColor;
  letter-spacing: 0.3rem;
  font-size: 2.8rem;
  padding: 2.5rem 0;
  height: auto !important;
}
.proprty-main-carousel-box {
  width: 1200px;
  height: 477px;
  background: url("https://staticweb.innochain.tech/web-product/product/lunbodi.png")
    no-repeat;
  background-position: 100% 100%;
  .proprty-main-carousel {
    width: 1200px;
    height: 477px;
  }
}
.contact-way-box {
  display: flex;
  flex: 1;
  justify-content: center;
  @contact-wayH: 359px;
  .lianxidi {
    width: 20rem;
  }
  .contact-way-info {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(224, 32, 32, 0.1);
    padding: 1rem 104px;
    border-radius: 4px;
    .contact-way-info-list {
      width: 268px;
      li {
        display: flex;
        border-bottom: 1px solid #dcdcdc;
        align-items: center;
        height: 40px;
        span {
          display: flex;
          width: 100px;
          color: @captionColor;
          font-size: 15px;
          line-height: 1.5rem;
          font-weight: 500;
        }
        input {
          display: flex;
          flex: 1;
          border: 0px;
          line-height: 1.5rem;
          margin-bottom: 5px;
        }
      }
    }
    .contact-way-info-list-subitm {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      width: 268px;
      height: 40px;
      background: linear-gradient(43deg, #ff8363 0%, #ff3d44 100%);
      border-radius: 4px;
      margin-top: 1rem;
      cursor: pointer;
    }
  }
}
::v-deep .el-select {
  display: flex;
  flex: 1;
  .select-trigger {
    display: flex;
    flex: 1;
  }
  .el-input__inner {
    display: flex;
    flex: 1;
    border: 0px;
    padding-left: 0px;
  }
}
.greenroom-presentation {
  height: 50vh;
  justify-content: flex-start;
}
.proprty-header-text {
  color: #000;
  font-size: 1.5rem;
  line-height: normal;
}
.connect-us {
  width: 100%;
  bottom: 0;
  position: absolute;
  img {
    width: 100%;
  }
}

.connect-box {
  flex-direction: row;
  background: linear-gradient(58deg, #ff8363 0%, #ff3d44 100%);
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 2rem;
}
.company-way {
  display: flex;
  flex: 2;
  margin: 30px 0;
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  .company-name {
    font-size: 24px;
    line-height: 33px;
  }
  .company-list {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    li {
      font-size: 14px;
      line-height: 25px;
    }
  }
}
.company-way-info {
  position: absolute;
  background: #00000082;
  color: #fff;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  span:nth-child(1) {
    margin-right: 30px;
  }
}
</style>