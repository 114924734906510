<template>
    <div class="home">
        <Home :isMobileType="isMobileType" v-if="productState === 'companyHome'" @watchVideoPlay="watchVideoPlay" @watchConnectUs="watchConnectUs" />
        <Property :isMobileType="isMobileType" v-if="productState === 'companyProperty'" />
        <BackOffice :isMobileType="isMobileType" v-if="productState === 'companyBackOffice'" />
        <League :isMobileType="isMobileType" v-if="productState === 'companyLeague'" />
        <BuildRelationships :isMobileType="isMobileType" v-if="productState === 'companyRelationships'" />
        <AllianceBusiness :isMobileType="isMobileType" v-if="productState === 'companyAlliance'" />
        <ConnectUs :isMobileType="isMobileType" v-if="productState === 'companyConnectUs'" @isToastFun="isToast" />
        <CompanyInfo v-if="productState === 'companyCompanyInfo'" />
        <div class="handle-hint handle-hint-up" @click="modificationProduct('up')" v-if="handleHintState=== 'up' || handleHintState=== 'center'">
            <div class="handle-lucency">
                <img src="../../assets/images/down-hint-icon.gif" alt="" style="transform: rotate(180deg);" />
            </div>
        </div>
        <div class="handle-hint" @click="modificationProduct('down')" v-if="handleHintState=== 'down' || handleHintState=== 'center'">
            <div class="handle-lucency">
                <img src="../../assets/images/down-hint-icon.gif" alt="" />
            </div>
        </div>
        <div class="product-position" style=" z-index: 10;background:#fff" v-if="WxClient === 'open'">
            <div class="about">
                如需浏览，请长按网站复制后使用浏览器访问
                <div style="font-size: 24px; margin-top: 10px">http://www.panhaodian.cn/</div>
            </div>
        </div>
        <Toast :toastName="toastName" v-if="toastType === 'open'" />
    </div>
</template>

<script>
    import Home from "./home"
    import Property from "./property"
    import BackOffice from "./back-office"
    import League from "./league"
    import BuildRelationships from "./build-relationships"
    import AllianceBusiness from "./alliance-business"
    import ConnectUs from "./connect-us"
    import CompanyInfo from "./company-info"
    import Toast from "@/components/toast"
    import { debounce, getDirection, getIsWxClient } from '@/assets/connJs/menu-func'
    export default {
        name: "Index",
        components: {
            Home,
            Property,
            BackOffice,
            League,
            BuildRelationships,
            AllianceBusiness,
            ConnectUs,
            CompanyInfo,
            Toast,
        },
        data() {
            //这里存放数据
            return {
                handleHintState: "down",
                isMobileType: "pc",
                productState: "companyHome",
                startx: 0,
                starty: 0,
                WxClient: "clean",
                toastName: "",
                toastType: "clone",
            }
        },
        mounted() {
            document.title = "深圳创链科技数据产品"
            this.isMobileType = "pc";
            // alert(screen.height)
            if (getIsWxClient()) {
                this.WxClient = "open"
            }
            // this.isToast(screen.height)
            if (this.isMobile()) {
                this.isMobileType = "mobile";
                //手指接触屏幕
                document.addEventListener("touchstart", this.handle, false);
                // //手指离开屏幕
                document.addEventListener("touchend", this.handleTouchend, false);
                return;
            }
            window.addEventListener('mousewheel', debounce(this.handleScroll), false)
            // 滚动事件
            window.addEventListener('DOMMouseScroll', debounce(this.handleScroll), false)
        },
        methods: {
            isToast(msg) {
                this.toastName = msg;
                this.toastType = "open";
                let tir = setTimeout(() => {
                    this.toastName = "";
                    this.toastType = "clone";
                    clearTimeout(tir);
                }, 3000)
            },
            isMobile() {
                document.documentElement.scrollTop = 0;
                let flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                );
                return flag;
            },
            watchVideoPlay() {
                window.open("https://v.youku.com/v_show/id_XNTE1MjUxMzc3Ng==.html")
            },
            watchConnectUs() {
                this.productState = "companyConnectUs"
            },
            handle(e) {
                this.startx = parseInt(e.touches[0].pageX);
                this.starty = parseInt(e.touches[0].pageY);
            },
            handleTouchend(e) {
                let { startx, starty } = this;
                let endx = e.changedTouches[0].pageX;
                let endy = e.changedTouches[0].pageY;
                var direction = getDirection(startx, starty, endx, endy);
                switch (direction) {
                    case 1:
                        this.modificationProduct("down")// 向上滑动
                        break;
                    case 2:
                        this.modificationProduct("up") // 向下滑动
                        break;
                }
            },
            handleScroll(e) {
                if (e.deltaY < 0) {
                    this.modificationProduct("up") // 负值为向上滚动
                }
                if (e.deltaY > 0) {
                    this.modificationProduct("down") // 正值为向下滚动，
                }
            },
            modificationProduct(type) {
                let productStateStr = this.productState || "companyHome"
                document.documentElement.scrollTop = 0;
                switch (productStateStr) {
                    case "companyHome":
                        if (type === "down") {
                            this.productState = "companyProperty";
                        }
                        break;
                    case "companyProperty":
                        if (type === "up") {
                            this.productState = "companyHome";
                        }
                        if (type === "down") {
                            this.productState = "companyBackOffice";
                        }
                        break;
                    case "companyBackOffice":
                        if (type === "up") {
                            this.productState = "companyProperty";
                        }
                        if (type === "down") {
                            this.productState = "companyLeague";
                        }
                        break;
                    case "companyLeague":
                        if (type === "up") {
                            this.productState = "companyBackOffice";
                        }
                        if (type === "down") {
                            this.productState = "companyRelationships";
                        }
                        break;
                    case "companyRelationships":
                        if (type === "up") {
                            this.productState = "companyLeague";
                        }
                        if (type === "down") {
                            this.productState = "companyAlliance";
                        }
                        break;
                    case "companyAlliance":
                        if (type === "up") {
                            this.productState = "companyRelationships";
                        }
                        if (type === "down") {
                            this.productState = "companyConnectUs";
                        }

                        break;
                    case "companyConnectUs":
                        if (type === "up") {
                            this.productState = "companyAlliance";
                        }
                        if (type === "down" && this.isMobileType == "mobile") {
                            this.productState = "companyCompanyInfo";
                        }
                        break;
                    case "companyCompanyInfo":
                        if (type === "up") {
                            this.productState = "companyConnectUs";
                        }
                        break;
                }
                if (productStateStr === "companyHome") {
                    if (type === "down") {
                        this.handleHintState = "center"
                    }
                } else if (productStateStr === "companyProperty") {
                    if (type === "up") {
                        this.handleHintState = "down"
                    }
                } else if (this.isMobileType == "mobile" && productStateStr === "companyConnectUs" || productStateStr === "companyCompanyInfo") {
                    if (type === "down") {
                        this.handleHintState = "up"
                    }
                } else if (this.isMobileType == "pc" && (productStateStr === "companyAlliance" || productStateStr == "companyConnectUs")) {
                    if (type === "down") {
                        this.handleHintState = "up"
                    }
                } else {
                    this.handleHintState = "center"
                }
            },
        },
    }
</script>
<style lang="less" >
@import "../../assets/connCss/index.less";
.home {
  display: flex;
  flex: 1;
  height: 100vh;
  background: #fff;
  flex-direction: column;
}
.property-presentation {
  display: flex;
  flex: 1;
  height: @screenH;
  background: linear-gradient(58deg, #ff8363 0%, #ff3d44 100%);
}
.property-presentation-mobile {
  .property-presentation;
  height: @screenH;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.greenroom-presentation {
  .property-presentation-mobile;
  background: #fff;
}

.reset-el-row {
  display: flex;
  flex: 1;
  height: -webkit-fill-available;
}
.grid-content-list {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cellphone-icon {
  width: 100%;
}
.headline-white {
  font-size: 2.44rem;
  font-weight: 600;
  line-height: 4rem;
  letter-spacing: 0.3rem;
}
.headline-black {
  .headline-white;
  color: #000;
}
.proprty-header-mobile {
  .headline-white;
  font-size: 1.9rem;
  padding-top: 7vh !important;
  color: #000;
}
.proprty-main-box {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-bottom: 3rem;
}
.handle-hint {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  bottom: 10px;
  .handle-lucency {
    width: 3rem;
    height: 1.5rem;
    border-radius: 15%;
    background: #cccccc8a;
  }
  img {
    width: 1.5rem;
  }
}
.handle-hint-up {
  bottom: auto;
  top: 10px;
}
.left-to-right {
  animation: left_right @timeing forwards;
}
.left-to-right-mobile {
  animation: left_right_mobile @timeing forwards;
}
.hide-to-show {
  animation: hide_show @timeing forwards;
}
.hide-content-show {
  animation: forwards 3s hide_show;
}
</style>
