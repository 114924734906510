<template>
    <div class="product-position">
        <div v-if="isMobileType==='pc'" class="property-presentation">
            <div class="property-presentation-box">
                <div class="proprty-header-mobile hide-content-show">
                    <div class="proprty-header-text">与更多优秀的企业一起共创，一起成长</div>
                </div>
                <div class="proprty-box hide-content-show">
                    <img :src="`${productUrl}/hezuoqiye.png`" class="hezuoqiye-icon">
                </div>
            </div>
        </div>
        <div v-else class="property-presentation">
            <div class="property-presentation-box">
                <div class="proprty-header-mobile hide-content-show">
                    <div class="proprty-header-mobile-text">与更多优秀的企业</div>
                    <div class="proprty-header-mobile-text">一起共创，一起成长</div>
                </div>
                <div class="proprty-main-carousel-mobile hide-content-show">
                    <img :src="`${productUrl}/alliance-business.png`" class="swiper-slide-mobile-icon" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AllianceBusiness",
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            }
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
            }
        },
        mounted() {
            this.productUrl = this.$productUrl;
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.property-presentation-box {
  display: flex;
  flex: 1;
  height: 95vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.proprty-header-mobile {
  padding: 0 !important;
}
.proprty-header-mobile-text {
  color: #fff;
  font-size: 2rem;
  line-height: 33px;
  letter-spacing: 3px;
}
.swiper-slide-mobile-icon {
  width: 86%;
  margin: 5% 7%;
}
.proprty-header-text {
  font-size: 2.5rem;
  color: #fff;
}
.proprty-box {
  display: flex;
  margin-top: 5vh;
  justify-content: center;
  align-items: center;
}
.hezuoqiye-icon {
  width: 75rem;
}
</style>
