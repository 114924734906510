
/*
 companyPhone: "153-0261-3750",
                companyQQ: "3471259827",
                mailInfo: "yqp@innochain.tech",
                companySite: "深圳市前深港合作区前湾一路35号前海深港青年梦工厂5栋3层307室",
                companyName: "深圳创链数据科技有限公司",
                recordsInfo: "（© 2018-2020 粤ICP备19000729号）",
                recordsInfoName: "粤公网安备 44030502003687号",
*/

export const COMPANY_PHONE = "153-0261-3750";
export const COMPANY_QQ = "3471259827";
export const MAIL_INFO = "yqp@innochain.tech";
export const COMPANY_SITE = "深圳市前深港合作区前湾一路35号前海深港青年梦工厂5栋3层307室";
export const COMPANY_NAME = "深圳创链数据科技有限公司";
export const RECORDS_INFO = "（© 2018-2020 粤ICP备19000729号）";
export const RECORDS_INFO_NAME = "粤公网安备 44030502003687号";
const debounce = (func, wait = 100, immediate = true) => {
    let timer
    return (...args) => {
        let context = this
        if (timer) clearTimeout(timer)
        if (immediate) {
            let callNow = !timer
            timer = setTimeout(() => {
                timer = null
            }, wait)
            if (callNow) func.apply(context, args)
        } else {
            timer = setTimeout(() => {
                func.apply(context, args)
            }, wait)
        }
    }
}

//获得角度
const getAngle = (angx, angy) => {
    return Math.atan2(angy, angx) * 180 / Math.PI;
};

//根据起点终点返回方向 1向上滑动 2向下滑动 3向左滑动 4向右滑动 0点击事件
const getDirection = (startx, starty, endx, endy) => {
    let angx = endx - startx;
    let angy = endy - starty;
    let result = 0;

    //如果滑动距离太短
    if (Math.abs(angx) < 2 && Math.abs(angy) < 2) {
        return result;
    }

    let angle = getAngle(angx, angy);
    if (angle >= -135 && angle <= -45) {
        result = 1;
    } else if (angle > 45 && angle < 135) {
        result = 2;
    } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
        result = 3;
    } else if (angle >= -45 && angle <= 45) {
        result = 4;
    }
    return result;
}
const getIsWxClient = () => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    }
    return false;
}

export { debounce, getDirection, getIsWxClient }
