<template>
    <div class="property">
        <div v-if="isMobileType==='pc'" class="product-position">
            <el-row :gutter="10" class="greenroom-presentation hide-content-show" style="flex-direction: inherit;height: 90vh;">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" class="reset-el-row ">
                    <el-row :gutter="10" class="reset-el-row ">
                        <el-col :xs="2" :sm="4" :md="4" :lg="6"></el-col>
                        <el-col :xs="22" :sm="20" :md="20" :lg="18" class="grid-content grid-content-list">
                            <div class="headline-black">让后台系统更加透明</div>
                            <div>
                                <div class="subheading-black">通过基于区块链的BAAS后台工具有效对企业组</div>
                                <div class="subheading-black">织架构及关键数据报表进行管理，帮助品牌更好</div>
                                <div class="subheading-black">地管理自己的店铺及投资人，让企业团队管理更</div>
                                <div class="subheading-black">加高效，店铺管理更加透明。</div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" class="reset-el-row">
                    <el-row :gutter="10" class="reset-el-row" style="align-items: flex-end;position: absolute;">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" class="grid-content">
                            <img :src="`${productUrl}/computer-icon.png`" alt="" class="computer-icon">
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div v-if="isMobileType==='mobile'" class="product-position">
            <div class="greenroom-presentation ">
                <div class="grid-content-mobile  grid-content-list hide-content-show">
                    <div class="headline-black">让后台系统更加透明</div>
                    <div>
                        <div class="subheading-black">通过基于区块链的BAAS后台工具有效对企业组</div>
                        <div class="subheading-black">织架构及关键数据报表进行管理，帮助品牌更好</div>
                        <div class="subheading-black">地管理自己的店铺及投资人，让企业团队管理更</div>
                        <div class="subheading-black">加高效，店铺管理更加透明。</div>
                    </div>
                </div>
                <div class="grid-content-mobile grid-content-list hide-content-show" style="padding-top:5rem">
                    <img :src="`${productUrl}/computer-icon.png`" alt="" class="lamplight">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Property",
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            }
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
            }
        },
        mounted() {
            this.productUrl = this.$productUrl;
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.subheading-white {
  font-size: 1.2rem;
  line-height: 2.5rem;
  display: flex;
  justify-content: flex-start;
}
.subheading-black {
  .subheading-white;
  color: #000;
}

.computer-icon {
  position: relative;
  left: -13rem;
  width: 57rem;
  top: -2.2rem;
}

.lamplight {
  width: 84vh;
}
</style>
