<template>
    <div class="property">
        <div v-if="isMobileType==='pc'">
            <el-row :gutter="10" class="property-presentation hide-content-show" id="assets_management">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" class="reset-el-row">
                    <el-row :gutter="10" class="reset-el-row " style="align-items: center;">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" class="grid-content">
                            <img :src="`${productUrl}/cellphone-icon.png`" alt="" class="cellphone-icon">
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" class="reset-el-row">
                    <el-row :gutter="10" class="reset-el-row">
                        <el-col :xs="22" :sm="20" :md="20" :lg="18" class="grid-content grid-content-list">
                            <div class="headline-white">让资产管理更加真实</div>
                            <div>
                                <div class="subheading-white">资产一键式转让、接受，快速完成资产转让，每 </div>
                                <div class="subheading-white">笔营收实时上链，资产状态随时查询，一起盘让 </div>
                                <div class="subheading-white">每一个拥有资产的人，智能化管理自己的资产， </div>
                                <div class="subheading-white">不再担心财务造假和信任危机，合作从此亲密无 </div>
                                <div class="subheading-white">间。</div>
                            </div>
                        </el-col>
                        <el-col :xs="2" :sm="4" :md="4" :lg="6"></el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div v-if="isMobileType==='mobile'" class="product-position">
            <div class="property-presentation-mobile">
                <div class="grid-content-mobile grid-content-list hide-content-show">
                    <div class="headline-white">让资产管理更加真实</div>
                    <div>
                        <div class="subheading-white">资产一键式转让、接受，快速完成资产转让，每 </div>
                        <div class="subheading-white">笔营收实时上链，资产状态随时查询，一起盘让 </div>
                        <div class="subheading-white">每一个拥有资产的人，智能化管理自己的资产， </div>
                        <div class="subheading-white">不再担心财务造假和信任危机，合作从此亲密无 </div>
                        <div class="subheading-white">间。</div>
                    </div>
                </div>
                <div class="grid-content-mobile grid-content-list hide-content-show" style="margin-bottom:5rem">
                    <img :src="`${productUrl}/cellphone-icon.png`" alt="" style="width:45vh">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Property",
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            }
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
            }
        },
        mounted() {
            // window.addEventListener("scroll", () => { this.initSlide(); })
            this.productUrl = this.$productUrl;
        },
        methods: {

        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.subheading-white {
  font-size: 1.2rem;
  line-height: 2.5rem;
  display: flex;
  justify-content: flex-start;
}
.subheading-black {
  .subheading-white;
  color: #000;
}
.cellphone-icon {
  width: 37rem;
  margin-left: 7rem;
  margin-top: 2rem;
}
.computer-icon {
  position: relative;
  left: -12rem;
  width: 57rem;
}
.slab-icon {
  width: 32rem;
  height: 26rem;
  margin-top: 10rem;
}
.lamplight {
  width: 100%;
}
.grid-content-padd {
  padding: 8rem 0;
  height: auto;
}
</style>
