<template>
    <div class="property-presentation  property-company-way hide-content-show">
        <div class="head-title">联系我们</div>
        <div class="company-box">
            <img :src="`${productUrl}/mobile-QR-code.png`" alt="" :style="{'width': !isIos?'80%':'60%'}">
        </div>
        <div class="company-way">
            <div class="company-way-list" @click="callphone"><img src="../../assets/images/phone-icon.png" alt="" class="connect-icon" style="margin-top:3px"> {{companyPhone}}</div>
            <div class="company-way-list" @click="PlayJsAdPopWin"><img src="../../assets/images/qq-icon.png" alt="" class="connect-icon" style="margin-top:1px"> {{companyQQ}}</div>
            <div class="company-way-list"><img src="../../assets/images/mail-icon.png" alt="" class="connect-icon" style="margin-top:6px">{{mailInfo}}</div>
            <div class="company-way-list"><img src="../../assets/images/address-icon.png" alt="" class="connect-icon">{{companySite}}</div>
        </div>
        <div class="company-way-info">
            <div>{{companyName}}<a href="https://beian.miit.gov.cn/">{{recordsInfo}}</a>{{recordsInfoName}}</div>
        </div>
    </div>
</template>

<script>
    import { COMPANY_PHONE, COMPANY_QQ, MAIL_INFO, COMPANY_SITE, COMPANY_NAME, RECORDS_INFO, RECORDS_INFO_NAME } from '@/assets/connJs/menu-func'
    export default {
        name: "CompanyInfo",
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            }
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
                companyPhone: COMPANY_PHONE,
                companyQQ: COMPANY_QQ,
                mailInfo: MAIL_INFO,
                companySite: COMPANY_SITE,
                companyName: COMPANY_NAME,
                recordsInfo: RECORDS_INFO,
                recordsInfoName: RECORDS_INFO_NAME,
                isAndroid: false,
                isIos: false,
            }
        },
        mounted() {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            this.isAndroid = isAndroid
            this.productUrl = this.$productUrl;
            if (window.screen.width <= 375) {
                this.isIos = true
            }
        },
        methods: {
            callphone() {
                window.location.href = `tel:${this.companyPhone}`;
            },
            PlayJsAdPopWin() {
                window.location.href = `tencent://message/?uin=${this.companyQQ}&Site=innochain&Menu=yes`
            },
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.property-company-way {
  display: flex;
  flex: none;
  padding: 0 5%;
  flex-direction: column;
  height: auto;
  overflow: hidden;
}
.head-title {
  display: flex;
  color: #fff;
  font-size: 2rem;
  margin-top: 2.5rem;
}
.company-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  img {
    width: 80%;
  }
}
.company-way {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 100px;
  color: #fff;
  font-size: 1rem;
  text-align: initial;
}
.company-way-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 2rem;
  img {
    margin-right: 5px;
  }
}
.company-way-info {
  position: absolute;
  background: #000;
  color: #fff;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding-left: 5%;
  text-align: initial;
}
</style>
