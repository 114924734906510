<template>
    <div class="property">
        <div v-if="isMobileType==='pc'">
            <el-row :gutter="10" class="property-presentation">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" class="reset-el-row">
                    <el-row :gutter="10" class="reset-el-row " style="    align-items: center;">
                        <el-col :xs="4" :sm="4" :md="4" :lg="6"></el-col>
                        <el-col :xs="20" :sm="20" :md="20" :lg="18" class="grid-content">
                            <img :src="`${productUrl}/slab-icon.png`" alt="" class="slab-icon hide-content-show">
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" class="reset-el-row">
                    <el-row :gutter="10" class="reset-el-row hide-content-show">
                        <el-col :xs="22" :sm="20" :md="20" :lg="18" class="grid-content grid-content-list ">
                            <div class="headline-white">让招商加盟更加可信</div>
                            <div>
                                <div class="subheading-white">通过真实营收数据，帮助品牌在招商加盟中提高</div>
                                <div class="subheading-white">自己的可信程度，让每一个想要找到理想资产的</div>
                                <div class="subheading-white">人拥有可与老板直接对话的权力，辅以自助大数</div>
                                <div class="subheading-white">据平台，让每一个手上有富余资金的人不再被虚</div>
                                <div class="subheading-white">拟平台欺诈。</div>
                            </div>
                        </el-col>
                        <el-col :xs="2" :sm="4" :md="4" :lg="6"></el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div v-if="isMobileType==='mobile'" class="product-position">
            <div class="property-presentation-mobile ">
                <div class="grid-content-mobile grid-content-list hide-content-show">
                    <div class="headline-white">让招商加盟更加可信</div>
                    <div>
                        <div class="subheading-white">通过真实营收数据，帮助品牌在招商加盟中提高</div>
                        <div class="subheading-white">自己的可信程度，让每一个想要找到理想资产的</div>
                        <div class="subheading-white">人拥有可与老板直接对话的权力，辅以自助大数</div>
                        <div class="subheading-white">据平台，让每一个手上有富余资金的人不再被虚</div>
                        <div class="subheading-white">拟平台欺诈。</div>
                    </div>
                </div>
                <div class="grid-content-mobile grid-content-list hide-content-show" style="padding-top:5rem">
                    <img :src="`${productUrl}/slab-icon.png`" alt="" class="lamplight">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Property",
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            }
        },
        data() {
            //这里存放数据
            return {
                productUrl: "",
            }
        },
        mounted() {
            this.productUrl = this.$productUrl;
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
.subheading-white {
  font-size: 1.2rem;
  line-height: 2.5rem;
  display: flex;
  justify-content: flex-start;
}
.slab-icon {
  position: relative;
  width: 35rem;
  right: 0;
  margin-top: 4rem;
}
.lamplight {
  width: 55vh;
}
</style>
