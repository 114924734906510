<template>
    <div class="product-position">
        <div v-if="isMobileType==='pc'" class="product-container">
            <el-container>
                <el-header class="proprty-header hide-content-show">帮助企业建立信任关系</el-header>
                <el-main class="proprty-main-box" style="margin: auto">
                    <div class="proprty-main-carousel-box hide-content-show">
                        <el-carousel class="proprty-main-carousel" :autoplay="true" :interval="interval">
                            <el-carousel-item v-for="(item,index) in productList" :key="index" class="proprty-main-carousel">
                                <img :src="productUrl +item.name" alt="" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </el-main>
            </el-container>
        </div>
        <div v-else>
            <el-container class="greenroom-presentation" style="flex-direction: column">
                <div class="proprty-header-mobile hide-content-show">帮助企业建立信任关系</div>
                <div class="proprty-main-carousel-mobile hide-content-show" style="flex-direction: column">
                    <swiper :pagination="{ clickable: true }" @slideChange="onSlideChange" class="proprtyl-mobile">
                        <swiper-slide class="swiper-slide-mobile" v-for="(item,index) in productMoblieList" :key="index">
                            <div class="swiper-slide-mobile-list">
                                <div>{{item.title}}</div>
                                <div class="swiper-slide-mobile-icon">
                                    <img :src="productUrl +item.name" alt="" />
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-slide-mobile-box">
                        <span :class="[activeIndex === 0 ? 'pagination-slide-atcive':'pagination-slide']">1</span>
                        <span :class="[activeIndex === 1 ? 'pagination-slide-atcive':'pagination-slide']">2</span>
                        <span :class="[activeIndex === 2 ? 'pagination-slide-atcive':'pagination-slide']">3</span>
                        <span :class="[activeIndex === 3 ? 'pagination-slide-atcive':'pagination-slide']">4</span>
                    </div>
                </div>
            </el-container>
        </div>
    </div>
</template>

<script>
    import SwiperCore, { Autoplay } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper-bundle.min.css';
    SwiperCore.use([Autoplay]);
    export default {
        name: "BuildRelationships",
        components: {
            Swiper,
            SwiperSlide,
        },
        props: {
            isMobileType: {
                type: String,
                default: "pc",
            }
        },
        data() {
            //这里存放数据
            return {
                interval: 5000,
                productList: [
                    { name: "/18dog02.png" },
                    { name: "/f502.png" },
                    { name: "/pailifang.png" },
                    { name: "/sanjintangbao.png" },
                ],
                productMoblieList: [
                    { name: "/bread-icon.png", title: "“改变连锁企业行业现状，用创链简化流程，形成“区块链+连锁零售”新模式。”" },
                    { name: "/f5.png", title: "“创链把区块链在实体经济的思想和方法，封装成一个用起来很爽的工具，极大地帮助整个行业提升基本效率。”" },
                    { name: "/18dog.png", title: "“创链让品牌方和投资人不再处于割裂的状态，将支付、上链、清分等系统整合在一起，让资产的收益可以与他人共享，这极大地降低了双方的信任成本。”" },
                    { name: "/molivideo-icon.png", title: "“工具是企业理念的载体，理念、文化、模式都需要通过工具去展现。工具不符合业务要求的话，协作、高效等口号容易落为空谈。”" },
                ],
                productUrl: "",
                activeIndex: 0,
            }
        },
        mounted() {
            this.productUrl = this.$productUrl;
        },
        methods: {
            onSlideChange(e) {
                this.activeIndex = e.activeIndex
            },
        },
    }
</script>
<style lang="less" scoped>
@import "../../assets/connCss/index.less";
@carouselH: 2.1rem;
.product-container {
  display: flex;
  flex: 1;
  height: 90vh;
  justify-content: center;
  align-items: center;
}
.property-presentation {
  .proprty-header {
    color: #fff;
  }
}
.proprty-header {
  font-weight: 600;
  color: @captionColor;
  letter-spacing: 0.3rem;
  font-size: 2.8rem;
  height: auto !important;
  margin: 0 auto 2rem;
}
.el-carousel__item img {
  width: 80%;
  margin: 5%;
}
.proprty-main-carousel-box {
  width: 1200px;
  height: 477px;
  background: url("https://staticweb.innochain.tech/web-product/product/lunbodi.png")
    no-repeat;
  background-position: 100% 100%;
  .proprty-main-carousel {
    width: 1200px;
    height: 477px;
  }
}

.contact-way-box {
  display: flex;
  @contact-wayH: 359px;
  .lianxidi {
    width: 632px;
    height: @contact-wayH;
  }
  .contact-way-info {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(224, 32, 32, 0.1);
    padding: 39px 104px;
    border-radius: 4px;
    .contact-way-info-list {
      width: 268px;
      li {
        display: flex;
        border-bottom: 1px solid #dcdcdc;
        margin-top: 10px;
        span {
          display: flex;
          width: 100px;
          color: @captionColor;
          font-size: 15px;
          line-height: 30px;
          font-weight: 500;
        }
        input {
          display: flex;
          flex: 1;
          border: 0px;
          line-height: 30px;
          margin-bottom: 5px;
        }
      }
    }
    .contact-way-info-list-subitm {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      width: 268px;
      height: 40px;
      background: linear-gradient(43deg, #ff8363 0%, #ff3d44 100%);
      border-radius: 4px;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}
::v-deep .el-select {
  display: flex;
  flex: 1;
  .select-trigger {
    display: flex;
    flex: 1;
  }
  .el-input__inner {
    display: flex;
    flex: 1;
    border: 0px;
    padding-left: 0px;
  }
}
.greenroom-presentation {
  .proprty-main-carousel-mobile {
    display: flex;
    flex: 1;
    width: 100%;
    margin-bottom: 2rem;
    .pagination-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;
      width: 2vh;
      height: 2vh;
      background: #d8d8d8;
      border-radius: 50%;
      margin-right: 1vh;
      font-size: 10px;
    }
    .pagination-slide-atcive {
      width: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      height: 2vh;
      background: #fa6400;
      border-radius: 10px;
      margin-right: 1vh;
      font-size: 10px;
    }
  }
  .proprtyl-mobile {
    width: 100%;
    height: 80%;
  }
  .swiper-slide-mobile {
    width: 100%;
    display: flex;
    .swiper-slide-mobile-list {
      display: flex;
      padding: 2vh 3vh;
      color: #666;
      font-size: 0.5rem;
      line-height: 2rem;
      text-align: left;
      flex-direction: column;
    }
    .swiper-slide-mobile-icon {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 10% 0 2%;
      img {
        width: 65%;
      }
    }
  }
}
.swiper-slide-mobile-box {
  display: flex;
  justify-content: center;
}
</style>
