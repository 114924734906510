<!--  -->
<template>
    <div class="toast-box">
        <div class="toast-txt">{{toastName}}</div>
    </div>
</template>

<script>
    //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
    //例如：import 《组件名称》 from '《组件路径》';

    export default {
        //import引入的组件需要注入到对象中才能使用
        props: {
            toastName: {
                type: String,
                default: "",
            }
        },

    }
</script>
<style lang="less" scoped>
.toast-box {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-txt {
  position: relative;
  color: #fff;
  font-size: 14px;
  background: #0000008c;
  padding: 10px 20px;
  border-radius: 5px;
}
</style>