<template>
    <router-view />
</template>
 
<style lang="less">
#app {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-overflow-scrolling: touch;
}
</style>